//alerts/AlertsList.vue

<template>
	<div class="container-fluid">
		<div class="row justify-content-between mb-4">
			<div class="col-sm">
				<h1>
					Alerts
					<div class="btn-group mb-1" role="group" v-if="options">
						<button
							v-on:click.stop.prevent="showFacilities = !showFacilities"
							id="btnGroupDrop1"
							type="button"
							class="btn btn-light btn-outline dropdown-toggle"
							data-toggle="dropdown"
							aria-haspopup="true"
							aria-expanded="false"
						>
							<small class="btn-label">facility</small>
							{{ filter.facility_name }}
						</button>
						<div
							class="dropdown-menu"
							:class="{ show: showFacilities }"
							v-on-clickaway="hideFacilities"
						>
							<a
								href
								@click.prevent="filterFacility(false)"
								class="dropdown-item"
								style="opacity: .4"
								>All Facilities</a
							>
							<a
								href
								@click.prevent="filterFacility(f)"
								v-for="(f, i) in options.facilities"
								:key="i + '-alert-options-facilities'"
								class="dropdown-item"
								>{{ f.name }}</a
							>
						</div>
					</div>
				</h1>
				<p class="lead text-muted">
					Manage system alerts to appear on facility tablets.
				</p>
			</div>
			<div class="col-auto mr-auto">
				<!-- <a class="btn btn-lg btn-success" v-on:click.prevent="newAlert()" href><i class="material-icons">&#xE145;</i>Add New</a> -->
				<router-link
					class="btn btn-lg btn-success"
					:to="{ name: 'alertFormAdd', params: {} }"
				>
					<i class="material-icons">&#xE145;</i>Add New
				</router-link>
			</div>
		</div>

		<div class="table-responsive">
			<table class="table -table-bordered">
				<thead>
					<tr>
						<th scope="col" style="text-align: center">#</th>
						<th scope="col">Text</th>
						<th scope="col">Start</th>
						<th scope="col">End</th>
						<th scope="col">Applies to</th>
						<th scope="col"></th>
					</tr>
				</thead>
				<tbody v-if="alerts">
					<tr v-for="n in alerts" :key="n.id" :class="'table-' + n.style">
						<td style="text-align: center">{{ n.id }}</td>
						<th>{{ n.text }}</th>
						<td>{{ n.start }}</td>
						<td>{{ n.end }}</td>

						<td>
							<span v-if="n.rtNow">rtNOW</span>
							<span class="opacity-40" v-else>Facility:</span>
							{{ n.facility_name }}
						</td>

						<td align="right" class="text-right">
							<a
								href
								class="btn btn-outline-danger"
								v-on:click.prevent="removeAlert(n.id, false)"
								>Remove</a
							>
							<router-link
								class="btn btn-outline-secondary ml-1"
								:to="{ name: 'alertFormEdit', params: { id: n.id } }"
								>Edit</router-link
							>
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<app-modal :modal="removeAlertModal" data-v-on:close-modal="closeModal">
			<template slot="title">Confirm Removal Alert</template>

			<form>
				<p>Are you sure you want to permanently remove this alert?</p>
			</form>

			<template slot="footer">
				<button
					type="button"
					class="btn btn-outline-secondary"
					data-dismiss="modal"
					v-on:click.prevent="removeAlertModal.show = false"
				>
					Never mind
				</button>
				<button
					type="button"
					class="btn btn-danger"
					v-on:click.prevent="removeAlert(removeAlertModal.id, true)"
					style="margin-right: .25em"
				>
					Remove
				</button>
			</template>
		</app-modal>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { directive as onClickaway } from "vue-clickaway";

export default {
	directives: {
		onClickaway: onClickaway
	},

	data() {
		return {
			showFacilities: false,
			filter: {
				facility_name: "All Facilities",
				facility_id: null
			},
			options: {
				facilities: []
			},
			removeAlertModal: {
				show: false,
				id: null // the id of the alert to remove
			},
			alerts: []
		};
	},

	computed: {
		...mapGetters(["loggedIn"])
	},

	watch: {
		// deep watch
		filter: {
			handler: function() {
				console.log("watch filter");
				this.refresh();
			},
			deep: true
		}
	},

	methods: {
		...mapActions(["login", "ajax"]),

		hideFacilities() {
			this.showFacilities = false;
		},

		filterFacility(f) {
			if (f) {
				this.filter.facility_id = f.id;
				this.filter.facility_name = f.name;
			} else {
				// resetting to all Facilities
				this.filter.facility_id = null;
				this.filter.facility_name = "All Facilities";
			}
			this.showFacilities = false;
		},

		submitForm() {
			console.log(this.email);
			console.log(this.password);

			this.login({
				email: this.email,
				password: this.password
			}).then(() => {});
		}, //submitForm

		//editAlert(id) {},

		removeAlert(id, confirmed) {
			if (!confirmed) {
				this.removeAlertModal.show = true;
				this.removeAlertModal.id = id;
			} else {
				this.removeAlertModal.show = false;
				this.removeAlertModal.id = null;

				//var tis = this;

				this.ajax({
					url: "/alert/delete/" + id,
					data: {},
					success: () => {
						// find the object in this array where event id matches
						var selected = this.alerts.find(function(ele) {
							return ele.id == id;
						});

						// find the array index of this object
						var i = this.alerts.indexOf(selected);

						// delete the array child at a given index
						this.$delete(this.alerts, i);
					}
				});
			}
		},

		refresh() {
			this.ajax({
				url: "/alert/get",
				data: { filter: this.filter },
				success: json => {
					//this.alerts = json.alerts;
					//this.options = json.options;

					this.$set(this, "alerts", json.alerts);
					this.$set(this, "options", json.options);
				}
			});
		}
	},

	mounted() {
		this.refresh();
	}
};
</script>

<style lang="scss"></style>
