// Dashboard.vue

<template>
	<form
		class="container-fluid"
		novalidate
		v-on:submit.prevent="submitForm"
		:class="{ 'was-validated': errors.items.length > 0 && formSubmitted }"
	>
		<div class="row justify-content-between mb-4">
			<div class="col-sm">
				<h1>{{ pageTitle }}</h1>
				<p class="lead text-muted">
					Manage application alert and define where it will show up.
				</p>
			</div>
			<div class="col-auto mr-auto">
				<a class="btn btn-lg -btn-success" href v-on:click.prevent="goBack()"
					>Cancel</a
				>
				<button
					:disabled="ajaxLoader"
					:class="{ 'btn-loading': ajaxLoader }"
					type="submit"
					class="btn btn-lg btn-success -btn-loading"
				>
					Save
				</button>
			</div>
		</div>

		<div class="alert" :class="'alert-' + alert.style" role="alert">
			<div class="form-group">
				<textarea-autosize
					v-validate="'required'"
					name="text"
					placeholder="Type something here..."
					ref="someName"
					v-model="alert.text"
					:min-height="1"
					:max-height="350"
					class="ghosted-textarea form-control"
					@blur.native="onBlurTextarea"
				></textarea-autosize>
			</div>
			<table class="_table">
				<tr style="border: none">
					<td>
						<label>Style</label>
						<div class="dropdown">
							<button
								v-on:click.stop.prevent="showTypeOptions = !showTypeOptions"
								class="btn dropdown-toggle"
								:class="'btn-' + alert.style"
								type="button"
								id="dropdownMenuButton"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
							>
								{{ styleCapticalized }}
							</button>
							<div
								class="dropdown-menu"
								aria-labelledby="dropdownMenuButton"
								:class="{ show: showTypeOptions }"
							>
								<a
									class="dropdown-item"
									href="#"
									@click.prevent="selectAlertType('primary')"
								>
									<span class="text-primary">Primary</span>
								</a>
								<a
									class="dropdown-item"
									href="#"
									@click.prevent="selectAlertType('secondary')"
								>
									<span class="text-secondary">Secondary</span>
								</a>
								<a
									class="dropdown-item"
									href="#"
									@click.prevent="selectAlertType('success')"
								>
									<span class="text-success">Success</span>
								</a>
								<a
									class="dropdown-item"
									href="#"
									@click.prevent="selectAlertType('danger')"
								>
									<span class="text-danger">Danger</span>
								</a>
								<a
									class="dropdown-item"
									href="#"
									@click.prevent="selectAlertType('warning')"
								>
									<span class="text-warning">Warning</span>
								</a>
								<a
									class="dropdown-item"
									href="#"
									@click.prevent="selectAlertType('dark')"
								>
									<span class="text-dark">Dark</span>
								</a>
							</div>
						</div>
					</td>
					<td>
						<label>Start On</label>
						<datepicker
							v-validate="'required'"
							name="start"
							v-model="alert.start"
							:format="'MM/dd/yyyy'"
							input-class="form-control"
						></datepicker>
					</td>
					<td>
						<label>End After</label>
						<datepicker
							v-validate="'required'"
							id="foo"
							name="end"
							v-model="alert.end"
							:format="'MM/dd/yyyy'"
							input-class="form-control"
						></datepicker>
					</td>
				</tr>
			</table>
		</div>

		<section>
			<div class="pt-5">
				<h3><i class="material-icons">voice_chat</i>rtNOW Alert</h3>
				<p class="text-muted">
					Assign this alert to any application using rtNOW.
				</p>
			</div>

			<pre>alert: {{ alert }}</pre>

			<div class="btn-group-toggle" data-toggle="buttons">
				<label
					class="btn btn-sm-"
					:class="{
						'text-light amber darken-3': alert.rtNow == 1,
						'btn-outline-secondary': alert.rtNow != 1
					}"
				>
					<input
						type="checkbox"
						v-model="alert.rtNow"
						id="rtnow_admin_option[]"
						:value="1"
					/>
					<i v-if="alert.rtNow == 1" class="material-icons -lime-text">check</i>
					<i v-else class="material-icons -plus">&#xE145;</i> Alert
				</label>
			</div>
		</section>

		<section>
			<div class="pt-5">
				<h3><i class="material-icons">location_city</i>Facility Alert</h3>
				<p class="text-muted">
					Assign this alert to your preferred facilities.
				</p>
			</div>

			<div class="table-responsive">
				<table class="table -table-bordered">
					<thead>
						<tr>
							<th scope="col" style="text-align: center">#</th>
							<th scope="col">Name</th>
							<th scope="col">State</th>
							<!-- <th align="right" style="text-align: right"><a class="btn btn-outline-primary" href="">Assign Hospital</a></th> -->
							<th style="text-align: center">Include Alert</th>
						</tr>
					</thead>
					<tbody v-if="options.facilities.length">
						<tr
							v-for="(facility, index) in options.facilities"
							:key="facility.id"
							style
						>
							<td style="text-align: center">{{ index + 1 }}</td>
							<th>{{ facility.name }}</th>
							<td>
								<strong>{{ facility.city }}</strong>
								, {{ facility.state }}
							</td>

							<td class="text-center">
								<div class="btn-group-toggle" data-toggle="buttons">
									<!-- <label class="btn btn-sm-" :class="{
                        'text-light blue darken-1':isFacilityChecked(facility.id),
                        'btn-outline-secondary':!isFacilityChecked(facility.id)
                      }">
                      <input
                        type="checkbox"
                        v-model="alert.facility"
                        id="rt_facility_option[]"
                          :value="facility.id"><i v-if="isFacilityChecked(facility.id)" class="material-icons -lime-text">check</i>
                          <i v-else="" class="material-icons -plus">close</i>Alert
                                    </label>-->
									<a
										class="btn btn-sm-"
										:class="{
											'text-light blue darken-1': isFacilityChecked(
												facility.id
											),
											'btn-outline-secondary': !isFacilityChecked(facility.id)
										}"
										@click.prevent="setFacilityAlert(facility.id)"
									>
										<i
											v-if="isFacilityChecked(facility.id)"
											class="material-icons -lime-text"
											>check</i
										>
										<i v-else class="material-icons -plus">&#xE145;</i>
										Alert
									</a>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</section>
	</form>
</template>

<script>
import moment from "moment";

import { mapGetters, mapActions } from "vuex";

import Datepicker from "vuejs-datepicker";

export default {
	//props: ['alert'],

	data() {
		return {
			pageTitle: "",
			formSubmitted: false,
			showTypeOptions: false,
			alert: {
				text: "The text goes here.",
				style: "secondary",
				start: moment().format("MM/DD/YYYY"), // today's date,
				end: moment().format("MM/DD/YYYY"), // today's date,
				id: null,
				rtNow: false,
				facility_id: null
			},
			options: {
				facilities: []
			},
			user: {}
		};
	},

	methods: {
		...mapActions(["ajax", "globalAlert"]),

		setFacilityAlert(id) {
			if (id == this.alert.facility_id) {
				// toggle it offset
				this.alert.facility_id = null;
			} else {
				this.alert.facility_id = id;
			}
		},

		isFacilityChecked(facilityId) {
			return this.alert.facility_id && this.alert.facility_id == facilityId;
		},

		submitForm() {
			this.formSubmitted = true;

			// make a copy of this object
			var alert = Object.assign({}, this.alert);

			alert.start = moment(this.alert.start).format("MM/DD/YYYY");
			alert.end = moment(this.alert.end).format("MM/DD/YYYY");

			console.log("alert");
			console.log(alert);

			var url =
				"/alert/save" +
				(this.$route.params.id ? "/" + this.$route.params.id : "");

			this.$validator.validateAll().then(result => {
				if (result) {
					//var id = this.alert.id ? this.alert.id : "";

					this.ajax({
						invalid: true, // require validation
						url: url,
						data: alert,
						success: json => {
							if (json.validation_errors) {
								//return false;
							} else if (json.error) {
								//return false;
							} else {
								this.formSubmitted = false;

								this.goBack();
							}
						}
					}); // ajax

					return;
				}

				this.globalAlert({
					title: "Form Errors",
					body: "Please correct errors."
				});
			}); // validateAll
		}, //  submit form

		onBlurTextarea() {},

		goBack() {
			this.$router.push("/alerts");
		},

		selectAlertType(type) {
			this.$set(this.alert, "style", type);
			this.showTypeOptions = false;
		}
	},

	computed: {
		...mapGetters(["ajaxLoader"]),

		styleCapticalized() {
			var s = this.alert.style;
			return s.charAt(0).toUpperCase() + s.slice(1);
		}
	},

	mounted() {
		var alert_id = this.$route.params.id;

		// if editing Alert
		if (this.$route.params.id) {
			this.pageTitle = "Edit Alert";

			this.alert.id = alert_id;

			this.ajax({
				url: "/alert/init/" + alert_id,
				//data: {},
				success: json => {
					//this.alert= json.alert;
					//this.options= json.options;

					this.$set(this, "alert", json.alert);
					this.$set(this, "options", json.options);
				}
			});
		}

		// new Alert
		else {
			this.pageTitle = "Add New Alert";

			this.alert.id = "";

			this.ajax({
				url: "/alert/init",
				data: {},
				success: json => {
					//tis.rt= json.rt;
					//tis.options = json.options;

					this.$set(this, "options", json.options);
				}
			});
		}
	},

	components: {
		Datepicker
	}
};
</script>

<style lang="scss"></style>
